<template>
    <section class="row mx-0">
        <div class="col-8 d-flex bg-white br-8 pb-3">
            <div class="col-auto pt-2">
                <p class="text-general f-600 f-17 mb-3">
                    {{$config.vendedor}} Aplica
                </p>
                <el-checkbox v-model="datosBasicos.todo_leechero" :true-label="1"
                             :false-label="0"
                             class="check-red"
                             @change="cambiarTodoLecheros($event,'todo_leechero')"
                >
                    Incluir todos los {{$config.vendedor}}
                </el-checkbox>
            </div>
            <div v-show="datosBasicos.todo_leechero === 0" class="col mt-3 position-relative">
                <div class="row mx-0">
                    <div class="col-8 pl-4 pr-0">
                        <p class="pl-3 text-general f-15">
                            Incluir {{$config.vendedor}}
                        </p>
                        <el-select v-model="valueLechero" placeholder="Seleccionar" filterable size="small" class="w-100" @change="agregarLechero($event)">
                            <el-option
                            v-for="t in lecheros"
                            :key="t.id"
                            :label="`${t.nombre} - (${t.cedis})`"
                            :value="t.id"
                            :disabled="Boolean(t.registrado)"
                            />
                        </el-select>
                    </div>
                </div>
                <div v-for="(ten,index) in lecherosAplicados" :key="index" class="row mx-0 my-3 align-items-center">
                    <div class="col-8 pl-4 pr-0">
                        <div class="d-middle border bg-whitesmoke2 px-2 py-1 br-4 f-12 text-general" style="height:32px;">
                            {{ ten.nombre }} - {{ ten.cedis }}
                            <el-tooltip placement="bottom" content="Eliminar" effect="light">
                                <i class="icon-cancel ml-auto cr-pointer f-18" @click="eliminarLechero(ten)" />
                            </el-tooltip>
                        </div>
                    </div>
                    <!-- <div class="col-auto d-middle px-0">
                    </div> -->
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import Cupones from '~/services/cupones/cupones'
export default {
    props:{
        idCupon:{
            type:Number,
            required:true
        },
        datosBasicos:{
            type:Object,
            required:true
        }
    },
    data(){
        return {
            valueLechero:null,
            lecheros:[],
        }
    },
    computed:{
        lecherosAplicados(){
            return this.lecheros.filter(o=>o.registrado === 1)
        }
    },
    mounted(){
        this.getLecheros()
    },
    methods:{
        async getLecheros(){
            try {
                const {data} = await Cupones.getLecherosCupon(this.idCupon)
                this.lecheros = data.lecheros
            } catch (e){
                this.error_catch(e)
            }
        },
        async agregarLechero(id){
            try {
                const form = {
                    id_tienda:id,
                    id_cupon:this.idCupon
                }
                const {data} = await Cupones.agregarLechero(this.idCupon, form)
                this.notificacion('','Lechero agregado al cupón','success')
                this.valueLechero = null
                let find = this.lecheros.find(o=>o.id == data.registro.id_tienda)
                find.registrado = 1

                // setTimeout(() => {
                //     this.moverAbajo();
                // }, 100);
                this.cambiarTodoLecheros(0,'todo_leechero')

            } catch (e){
                this.error_catch(e)
            }
        },
        async cambiarTodoLecheros(value,campo){
            if(value==0 && this.lecherosAplicados.length==0) return
            try {
                let form = {
                    columna:campo,
                    value
                }
                const {data} = await Cupones.cambiarCondiciones(this.idCupon,form)
                
            } catch (e){
                this.error_catch(e)
            }
        },
        async eliminarLechero(item){
            try {

                await Cupones.eliminarLechero(this.idCupon, item.id)
                this.notificacion('','Lechero removido del cupón','success')
                let find = this.lecheros.find(o=>o.id == item.id)
                find.registrado = 0

            } catch (e){
                this.error_catch(e)
            }
        },
        moverAbajo(){
            let elementDiv = document.getElementById('cont-scroll');
            const VALUE = elementDiv.scrollHeight - elementDiv.clientHeight;
            elementDiv.scrollTo({
                top: VALUE,
                left: 0,
                behavior: 'smooth'
            })
        }
    }
}
</script>
